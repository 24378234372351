<template>
	<div class="appMain">
		<web-header></web-header>
		<div class="content main">
			<div>
				<div class="success" v-if="this.$route.query.index === '3'">
					<img style="width: 50px;height: 30px" src="../../assets/index/yinlian.png" alt="">
					<span>欢迎使用银联二维码</span>
				</div>
				<div class="success" v-else-if="this.$route.query.index === '1'">
					<img style="width: 50px;height: 30px" src="../../assets/index/wxzhifu.png" alt="">
					<span>请使用微信扫一扫支付</span>
				</div>
			</div>
			<div class="contentOne">
				<div class="oneLeft">
					<p>请您尽快付款！</p>
					<p>请您在提交订单后30分钟内完成支付，否则订单会自动取消。</p>
				</div>
				<div class="oneRight">
					<p>应付金额 <span>{{list.cost|| list.unitPrice}}</span> 元</p>
					<!-- <div>订单详情 <i style="margin-left: 20px" class="el-icon-caret-top"></i></div> -->
				</div>
			</div>
			<div class="contentTwo">
				<!-- <el-button @click="payRwsult(list)">支付结果</el-button> -->
				<!-- <div class="payee">
          收款方： 
          <span>重庆继教科技有限公司</span>
        </div> -->
				<div class="erweima">
					<div class="qrcode" ref="qrCodeUrl"></div>
				</div>
				<!-- <div style="text-align: center;">
					<el-button type="primary" class="cancelPay" @click="cancelPay(list)">取消支付</el-button>
				</div> -->
				<div style="text-align: center;" v-if="this.$route.query.index === '3'">
					可以使用各种客户端进行扫码(例如：微信，支付宝，京东钱包，百度钱包，银闪付，各银行客户端等形式扫码支付)
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import webHeader from '../../components/webHeader.vue'
	import QRCode from 'qrcodejs2'
	export default {
		components: {
			webHeader
		},
		data() {
			return {
				erweimaUrl: {},
				html: '',
				list: {},
				data: {},
				interval: null,
				orderCode: ''
			}
		},
		mounted() {
			if (this.$route.query && this.$route.query.index === '2') {
				this.zfbPay()
			} else {
				this.erweimaUrl = this.$route.query.url[0]
				this.creatQrCode(this.erweimaUrl);
				this.list = this.$route.query.data
				//实现轮询
				this.interval = window.setInterval(() => {
					setTimeout(this.getOrderid(), 0);
				}, 3000);
			}

		},
		methods: {
			// 微信查询订单流水号
			getOrderid() {
				this.$http.post('wxById', {
					orderCode: this.$route.query.url[1]
				}).then(res => {
					console.log('支付成功了吗', res);
					if (res.code === 200) {
						this.$router.push({
							path: '/trainingProgram/paymentResults',
							query: {
								data: this.list,
								orderCode: this.$route.query.url[1]
							}
						})
					}
				})
			},
			creatQrCode() {
				var qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: this.erweimaUrl,
					width: 200,
					height: 200,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
			// 支付宝界面
			zfbPay() {
				this.html = this.$route.query.url
				let divForm = document.getElementsByTagName('divform')
				if (divForm.length) {
					document.body.removeChild(divForm[0])
				}
				const div = document.createElement('divform')
				div.innerHTML = this.html // res.data就是sb支付宝返回给你的form
				document.body.appendChild(div)
				// document.forms[0].setAttribute('target', '_blank') // 加了_blank可能出问题所以我注释了
				document.forms[0].submit();
			},
			payRwsult(e) {
				this.$router.push({
					path: '/trainingProgram/paymentResults'
				})
			},
			// 取消支付
			cancelPay(e) {
				this.$confirm('确定要取消订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post('cancellationOrder', {
						id: e.id
					}).then(res => {
						if (res.code === 200) {
							this.$message.success('订单取消成功')
							this.$router.push('/')
						} else {
							this.$message.error(res.message)
						}
					})
				})
			}
		},
		beforeDestroy() {
			//清除轮询   
			clearInterval(this.interval)
			this.interval = null
		},

	}
</script>

<style lang="less" scoped>
	.w {
		width: 1200px;
		margin: 0 auto;
	}

	.appMain {
		background: #eee;
		height: 1080px;
	}

	.main {
		width: 1200px;
		margin: 0 auto;
	}

	.content {
		height: 600px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		margin-top: 16px;
		padding: 40px 70px;
	}

	.contentOne {
		display: flex;
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 30px;
		justify-content: space-between;

		.oneLeft {
			p:nth-child(1) {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 600;
				color: #333333;
				margin-bottom: 20px;
			}

			p:nth-child(2) {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #666666;
			}
		}

		.oneRight {
			margin-right: 120px;

			p {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				margin-bottom: 20px;

				span {
					color: #39AEFF;
					font-size: 20px;
				}
			}

			div {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
			}
		}
	}

	.erweima {
		position: relative;
		height: 200px;
		text-align: center;
		margin-top: 20px;

		.qrcode {
			position: absolute;
			left: 42%;
			margin-right: 200px;
		}
	}

	.twoTop {
		font-size: 14px;
		margin-top: 20px;
	}

	.success {
		margin-bottom: 24px;

		img {
			vertical-align: top;
		}
	}

	.payee {
		font-size: 14px;
	}

	.cancelPay {
		font-size: 14px;
		width: 220px;
		height: 30px;
		margin-top: 10px;
		text-align: center;
		line-height: 9px;
		border-radius: 20px;
		background-color: #1D5DE0;
	}
</style>
